<template>
  <div class="outer-container">
    <div class="inner-container">
      <div class="text-container">
        <p><b>Kend Dit Folketing</b> er en platform, der giver indsigt i, hvad der sker i Folketinget, Danmarks lovgivende forsamling.</p>
        <p>Kend Dit Folketing er lavet af <a href="http://www.ogtal.dk" target="_blank">Analyse & Tal</a> i samarbejde med <a href="http://www.altinget.dk" target="_blank">Altinget</a> og støttet af Google Digital News Innovation Fund. </p>
        <p><a href="http://www.ogtal.dk" target="_blank">Analyse & Tal</a> er specialiseret i at skabe nye indsigter i vores samfund ved at arbejde med big data og digitale metoder. </p>
        <p>Spørgsmål og kommentarer kan rettes til Analyse & Tal på <a href="mailto:info@ogtal.dk">info@ogtal.dk</a>.</p>
        
        <h2 class="sub">Datagrundlag</h2>
        <p>Data blev sidst opdateret <b>1. februar 2019</b>, og går tilbage til 2011.</p>
        <p>Alt på denne hjemmeside bygger på åbne data fra folketinget.dk. Hjemmesiden bygger primært på data om, hvad der er sket i folketingssalen siden 2011, det vil sige: </p>

        <ul>
          <li>Informationer om lov- og beslutningsforslag, som er blevet behandlet</li>
          <li>Afstemninger </li>
          <li>Referater af alle taler, som har været afholdt i folketingssalen.</li>
        </ul>

        <p>Hjemmesiden bygger desuden på følgende data:</p>

        <ul>
          <li>Alle tekster og billeder om de politiske partier og politikere er hentet fra Folketingets <a href="https://www.ft.dk/da/partier/om-politiske-partier/partiernes-historie" target="_blank">hjemmeside</a></li>
          <li>Oversigten over udviklingen i partiernes medlemstal er hentet fra Folketingets <a href="https://www.ft.dk/~/media/sites/ft/pdf/partier/om-politiske-partier/tal-og-fakta-om-partier/partiernes-medlemstal-fra-1960.ashx?la=da" target="_blank">egen oversigt</a></li>
          <li>Data om folketingsmedlemmernes køn og alder er hentet fra <a href="http://everypolitician.org/denmark/" target="_blank">Every Politician</a></li>
        </ul>

        <p>Du kan læse mere om Folketingets åbne data og <a href="https://www.ft.dk/da/Dokumenter/Aabne_data" target="_blank">deres API her</a>. </p>

        <h2 class="sub">Indhold</h2>

        <p><b>De politiske emner</b><br />
          Analyse & Tal har kategoriseret samtlige lov- og beslutningsforslag og dertilhørende taler siden 2011 under 24 politiske emner. Kategoriseringen er ikke begrænset til et emne. Det betyder, at et forslag sagtens kan være kategoriseret under flere emner, hvis indholdet handler om flere emner.
        </p>

        <p><b>Talerne</b><br />
          Alle analyser af, hvordan politikerne i Folketinget taler om forskellige politiske emner, er baseret på Folketingets egne referater fra møder i Folketingssalen. Der publiceres ikke referater fra samråd og udvalgsmøder, hvorfor dette ikke er en del af datagrundlaget. Du kan <a href="https://www.ft.dk/da/ofte-stillede-spoergsmaal/udv_hvor-finder-jeg-referater-af-samraad-og-udvalgsmoeder" target="_blank">læse mere om hvorfor her</a>.

          <p>Folketinget har kun gjort referater tilbage til 2009 tilgængelige gennem det maskinlæsbare format XML. Det er alligevel nogle år, der mangler en række referater. Når Folketinget publicerer nye referater, vil siden fortløbende blive opdateret. </p>

          <table>
            <thead>
              <tr>
                <th>Samling</th>
                <th>Antal møder</th>
                <th>Antal referater</th>
                <th>Dækning</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>2009 - 2010</td>
                <td>109</td>
                <td>107</td>
                <td>98%</td>
              </tr>
              <tr>
                <td>2010 - 2011</td>
                <td>110</td>
                <td>110</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2011 - 2012</td>
                <td>102</td>
                <td>102</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2012 - 2013</td>
                <td>115</td>
                <td>111</td>
                <td>96%</td>
              </tr>
              <tr>
                <td>2013 - 2014</td>
                <td>109</td>
                <td>109</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2014 - 2015</td>
                <td>104</td>
                <td>104</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2015 - 2016</td>
                <td>112</td>
                <td>112</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2016 - 2017</td>
                <td>113</td>
                <td>113</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2017 - 2018</td>
                <td>108</td>
                <td>108</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>2018 - 2019</td>
                <td>60</td>
                <td>60</td>
                <td>100%</td>
              </tr>              
            </tbody>
          </table>

          <p><b>Afstemninger</b><br />
            Alle analyser af, hvordan partierne har stemt i de forskellige regeringsperioder, er baseret på Folketingets egne optællinger af afstemningerne. </p>

          <p><b>Løsgængere og de nordatlantiske mandater</b><br />
            Hjemmesiden inkluderer udelukkende de danske politiske partier. Det betyder, at løsgængere og de fire nordatlantiske mandater ikke er med i opgørelserne. </p>

          <h2 class="sub">Metoderne bag visualiseringerne</h2>

          <p><b>Hvem har mest på hjerte?</b><br />
            Overblikket baserer sig på en udregning af, hvor længe de forskellige MF'er har talt under debatter, som hører til det pågældende politiske emne. Talerne kan berøre flere politiske emner og vil i de tilfælde blive talt flere gange.</p>

          <p><b>Hvor meget fylder området?</b><br />
            Her vises, hvor mange lov- og beslutningsforslag der tilhører de forskellige emner. Enkelte forslag kan berøre flere politiske emner og vil i de tilfælde blive talt flere gange.</p>

          <p><b>Vennerne</b><br />
            Her vises, hvor ofte partierne stemmer sammen inden for det valgte emne. Stemmedata er genereret på baggrund af Folketingets afstemningskonklusioner og er derfor aggregeret på partiniveau. Det vil sige, hvis enkelte MF'er stemmer imod partilinjen, så tæller de ikke med i vores stemmeopgørelser.</p>

          <p><b>Er blokkene i opløsning?</b><br />
            Her har vi brugt en <i>dimensionality reduction algoritme</i> til at visualisere stemmemønstre i to dimensioner. Partiernes stemmemønstre er præsenteret i en flerdimensionel matrice, som indeholder data om, hvor ofte parti x har stemt sammen med parti y. Hvert parti er i denne matrice repræsenteret som række/vektor. Den euklidiske afstand imellem disse vektorer er udregnet og brugt som input til sklearns <i>multidimensional scaling-algoritme</i> med default parametervalg. Outputtet af denne algoritme er derefter roteret, således at partier fra blå blok så vidt muligt vises i højre side af visualiseringen.</p>

          <p><b>Vindende alliancer</b><br />
            Her har vi udregnet, hvor ofte alliancer får stemt forslag igennem inden for det valgte emne. Illustrationen viser de tre alliancer, som har stemt flest forslag igennem. </p>

          <p><b>Kontroversielle lov- og beslutningsforslag</b><br />
            Her har vi udvalgt de lov- og beslutningsforslag, som er blevet debatteret i længst tid i Folketingssalen.</p>

          <p><b>Hvem sagde hvad?</b><br />
            Her har vi udregnet, hvor ofte partier og MF'er bruger forskellige ord, når de debatterer forslag inden for emnet. For kun at vise meningsbærende ord har vi filtreret teksten ad tre omgange. Først har vi fjernet stopord (ord som og, eller, osv.) og derefter navne på politikere og partier. Til sidst har vi for hvert ord udregnet en TF-IDF-score for hvert emne og taget gennemsnittet af disse. Vi har så fjernet de ord som har en gennemsnitlig TF-IDF-score over en bestemt grænseværdi. Dette sikrer, at ord, der optræder på tværs af de politiske emner, ikke kommer med. </p>

          <p><b>Hvilke emner bruger partierne mest tid på i folketingssalen?</b><br />
            En opgørelse af, hvor mange minutter partierne bruger på at debattere forslag i folketingssalen, fordelt på de politiske emner. </p>

          <p><b>Venner og fjender</b><br />
            Her vises, hvor ofte partierne stemmer sammen inden for det valgte emne. Stemmedata er genereret på baggrund af Folketingets afstemningskonklusioner og er derfor aggregeret på partiniveau.</p>

          <p><b>Sådan taler de</b><br />
            Tekstdata fra debatterne er i første omgang blevet behandlet på samme måde som ved visualiseringen <i>Hvem sagde hvad</i>. Derefter har vi fundet de 50 mest brugte ord frem. </p>
          <p>For at finde ud af, hvilke partier og medlemmer der snakker mest ens, er alle teksterne fra debatterne lavet om til TF-IDF-vektorer, og cosinus-afstande imellem disse er brugt som et mål for, hvor ens man taler. </p>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
.outer-container {
  height: 100%;
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inner-container {
  width: 50%;
  display: flex;
  align-items: center;
}
.text-container {
  width: 90%;
  margin: 10% 0 10% 0;
  h1.title {
    text-align: left;
  }
  h2.sub {
    font-family: "futura-pt-condensed";
    font-weight: 800;
    font-size: 200%;
    line-height: 3.5rem;
    text-align: left;
  }
  p,
  table,
  th,
  td {
    text-align: left;
    // font-family: "futura-pt-condensed",sans-serif;
    font-family: "roboto-condensed";
    font-weight: 500;
    font-size: 100%;
    line-height: 1.5em;
    color: #000;
  }
  ul {
    text-align: left;
    // font-family: "futura-pt-condensed",sans-serif;
    font-family: "roboto-condensed";
    font-weight: 500;
    font-size: 90%;
    line-height: 1.5em;
    color: #000;
  }
  a {
    color: $color-orange;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 70%;
    margin-bottom: 30px;
  }
  th,
  td {
    padding: 10px;
    margin: 0;
    font-size: 90%;
  }
  th {
    font-weight: bold;
    background-color: $color-lightgrey;
  }
  tr {
    margin: 0;
  }
  tr:nth-child(even) {
    background-color: $color-lightgrey;
  }
}

@media (max-width: $bp-narrow) {
  .inner-container {
    width: 80%;
  }
  .text-container {
    margin: 5% 0 5% 0;
    p {
      text-align: left;
      font-family: "roboto-condensed";
      font-weight: 500;
      font-size: 120%;
      color: #000;
    }
    table,
    th,
    td {
      font-size: 100%;
    }
    h2.sub {
      margin: 5% 0 5% 0;
      padding: 0;
      line-height: 1rem;
    }
    ul {
      margin: 0;
      padding-left: 20px;
    }
  }
}
</style>

<script>
export default {
  name: "about"
};
</script>
